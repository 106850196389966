import * as types from './types';

const initialState = {
  fetching: false,
  value: null,
  networkError: false,
  validity: {
    valid: null,
    reset: null,
    message: null,
  },
  upgradesValidity: null,
  changedUpgradePrice: {
    changed: false,
    price: null,
    newPriceAccepted: false,
  },
  requestedDepartureFlightMissing: false,
  travelDocumentRequired: false,
  travelDocumentResidenceCountryRequired: false,
  travelDocumentNationalityRequired: false,
  travelDocumentIssuedRequired: false,
  travelDocumentIssuingCityRequired: false,
  acceptedTravelDocumentTypes: [],
};

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.SET_STATE:
      return payload.state;

    case types.REQUEST_FLIGHTS_DETAILS:
      return requestFlightsDetails(state, payload);

    case types.SET_FLIGHTS_DETAILS_VALUE:
      return setFlightsDetailsValue(state, payload);

    case types.SET_VALIDITY:
      return setValidity(state, payload);

    case types.SET_UPGRADES_VALIDITY:
      return setUpgradesValidity(state, payload);

    case types.UPDATE_UPGRADES_VALIDITY:
      return updateUpgradesValidity(state, payload);

    case types.SET_CHANGED_UPGRADE_PRICE:
      return setChangedUpgradePrice(state, payload);

    case types.SET_NETWORK_ERROR_VALUE:
      return setNetworkError(state, payload);

    case types.SET_REQUESTED_DEPARTURE_FLIGHT_MISSING:
      return setRequestedDepartureFlightMissing(state, payload);

    case types.SET_TRAVEL_DOCUMENTS:
      return setTravelDocuments(state, payload);

    case types.RESET_FLIGHTS_DETAILS:
      return initialState;

    default:
      return state;
  }
}

export default reducer;

function requestFlightsDetails(state, { fetching }) {
  return {
    ...state,
    fetching,
  };
}

function setFlightsDetailsValue(state, { value }) {
  return {
    ...state,
    fetching: false,
    value,
  };
}

function setValidity(state, { validity }) {
  return {
    ...state,
    validity,
  };
}

function setUpgradesValidity(state, { upgradesValidity }) {
  return {
    ...state,
    upgradesValidity,
  };
}

function updateUpgradesValidity(state, { serviceKey, direction, passengerType, passengerIndex, validity }) {
  return {
    ...state,
    upgradesValidity: {
      ...state.upgradesValidity,
      [serviceKey]: {
        ...state.upgradesValidity[serviceKey],
        [direction]: {
          ...state.upgradesValidity[serviceKey][direction],
          [passengerType]: {
            ...state.upgradesValidity[serviceKey][direction][passengerType],
            [passengerIndex]: validity,
          },
        },
      },
    },
  };
}

function setNetworkError(state, { networkError }) {
  return {
    ...state,
    networkError,
  };
}

function setChangedUpgradePrice(state, { changedUpgradePrice }) {
  return {
    ...state,
    changedUpgradePrice,
  };
}

function setRequestedDepartureFlightMissing(state, { requestedDepartureFlightMissing }) {
  return {
    ...state,
    requestedDepartureFlightMissing,
  };
}

function setTravelDocuments(
  state,
  {
    travelDocumentRequired,
    travelDocumentResidenceCountryRequired,
    travelDocumentNationalityRequired,
    travelDocumentIssuedRequired,
    travelDocumentIssuingCityRequired,
    acceptedTravelDocumentTypes,
  }
) {
  return {
    ...state,
    travelDocumentRequired,
    travelDocumentResidenceCountryRequired,
    travelDocumentNationalityRequired,
    travelDocumentIssuedRequired,
    travelDocumentIssuingCityRequired,
    acceptedTravelDocumentTypes,
  };
}
