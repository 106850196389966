import moment from 'moment';
import * as types from './types';

const initialState = {
  floatingPackagePrice: {
    clicked: null,
  },
  transitionToCheckout: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_STATE:
      return action.payload.state;

    case types.SET_FLOATING_PACKAGE_PRICE_CLICKED:
      return setFloatingPackagePriceClicked(state);

    case types.SET_TRANSITION_TO_CHECKOUT:
      return setTransitionToCheckout(state, action.payload);

    default:
      return state;
  }
}

export default reducer;

function setFloatingPackagePriceClicked(state) {
  return {
    ...state,
    floatingPackagePrice: {
      clicked: moment().unix(),
    },
  };
}

function setTransitionToCheckout(state, { transitionToCheckout }) {
  return {
    ...state,
    transitionToCheckout,
  };
}
