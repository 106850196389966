import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as flightDirections from 'state/checkoutPage/flightsDetails/constants/flightDirections';
import servicesIconsMap from 'routes/checkoutPage/constants/servicesIconsMap';
import SelectUpgrade from './components/selectUpgrade/SelectUpgrade';
import UpgradesCommonComponent from '../common/UpgradesCommonComponent';

function MultipleChoiceUpgradeComponent({
  serviceKey,
  title,
  upgrades,
  passengerType,
  passengerIndex,
  airlineWhitelabel,
}) {
  return (
    <UpgradesCommonComponent>
      <Card>
        {title && (
          <UpgradesCommonComponent.Header>
            <UpgradesCommonComponent.Dot />
            {title}
          </UpgradesCommonComponent.Header>
        )}
        <CardBody airlineWhitelabel={airlineWhitelabel}>
          {upgrades.map(({ direction, options, includedService, carrierName }, index) => {
            let _options = undefined;

            if (options && options.length > 0) {
              _options = [
                {
                  label: '',
                  value: 'na',
                  data: {
                    description: {
                      id: `noUpgradeSelected.${serviceKey}`,
                      description: `No ${serviceKey}.`,
                      defaultMessage: `No ${serviceKey}.`,
                    },
                  },
                },
                ...options,
              ];
            }

            return (
              <Content key={`${serviceKey}-upgrade-${index}`}>
                <UpgradesCommonComponent.Direction direction={direction} carrierName={carrierName} />
                {includedService && (
                  <UpgradesCommonComponent.IncludedServices includedService={includedService}>
                    <FormattedMessage
                      id={'includedService.label'}
                      description={'Included'}
                      defaultMessage={'Included'}
                    />
                  </UpgradesCommonComponent.IncludedServices>
                )}
                {!includedService && _options && (
                  <NoUpgradesWrap>
                    <Message>
                      <FormattedMessage
                        id={`notIncludedServiceMessage.${serviceKey}.label`}
                        description={`No ${serviceKey} upgrades available`}
                        defaultMessage={`No ${serviceKey} upgrades available`}
                      />
                    </Message>
                  </NoUpgradesWrap>
                )}
                {_options && (
                  <SelectUpgrade
                    options={_options}
                    serviceKey={serviceKey}
                    direction={direction}
                    passengerType={passengerType}
                    passengerIndex={passengerIndex}
                    forceSelection
                    scrollTo
                  />
                )}
                {!_options && !includedService && (
                  <NoUpgradesWrap>
                    <Message>
                      <FormattedMessage
                        id={`includedServiceMessage.${serviceKey}.label`}
                        description={`No ${serviceKey} upgrades available`}
                        defaultMessage={`No ${serviceKey} upgrades available`}
                      />
                    </Message>
                  </NoUpgradesWrap>
                )}
              </Content>
            );
          })}
        </CardBody>
      </Card>
    </UpgradesCommonComponent>
  );
}

export default MultipleChoiceUpgradeComponent;

const Card = styled.div`
  margin-bottom: 20px;
`;

const CardBody = styled.div`
  font-weight: ${({ airlineWhitelabel }) => (airlineWhitelabel && airlineWhitelabel.isAirBaltic ? 500 : 600)};
  color: ${({ theme }) => theme.colors.text.light};
`;

const Content = styled.div`
  padding-bottom: 15px;
`;

const Message = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.text.light};
  font-size: 1.4em;
  font-weight: 500;
  opacity: 0.7;
`;

const NoUpgradesWrap = styled.div`
  display: flex;
  margin-bottom: 8px;
  border: ${({ theme }) => `solid 1px ${theme.colors.inputs.border.default}`};
  padding: 11px 0px 11px 14px;
  align-items: 12px;
`;
