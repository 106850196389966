import React from 'react';
import Upgrade from './components/upgrade/Upgrade';
import UpgradesCommonComponent from '../common/UpgradesCommonComponent';

function SingleChoiceUpgradeComponent({ serviceKey, title, upgrades, passengerType, passengerIndex, currency }) {
  if (!upgrades || upgrades.length === 0) {
    return null;
  }
  return (
    <UpgradesCommonComponent>
      {title && (
        <UpgradesCommonComponent.Header>
          <UpgradesCommonComponent.Dot />
          {title}
        </UpgradesCommonComponent.Header>
      )}
      {upgrades.map(
        ({ pieces, direction, departureAirport, destinationAirport, carrierName, carriers, perPassenger }) =>
          pieces &&
          pieces.map(({ upgradeToken, name, price, items }, index) => (
            <Upgrade
              key={`${direction}-${index}`}
              passengerType={passengerType}
              passengerIndex={passengerIndex}
              serviceKey={serviceKey}
              currency={currency}
              direction={direction}
              departureAirport={departureAirport}
              destinationAirport={destinationAirport}
              carrierName={carrierName}
              carriers={carriers}
              upgradeToken={upgradeToken}
              name={name}
              items={items}
              price={price}
              perPassenger={perPassenger}
            />
          ))
      )}
    </UpgradesCommonComponent>
  );
}

export default SingleChoiceUpgradeComponent;
