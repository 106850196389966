import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'i18n';

import { mediaQueries } from 'styles/utils/mediaQueries';
import * as flightDirections from 'state/checkoutPage/flightsDetails/constants/flightDirections';
import servicesIconsMap from 'routes/checkoutPage/constants/servicesIconsMap';

const directionIcon = {
  [flightDirections.RETURN]: servicesIconsMap.PlaneArrival,
  [flightDirections.BOTH]: servicesIconsMap.Plane,
  [flightDirections.LEAVE]: servicesIconsMap.PlaneDeparture,
};

function Dot() {
  return <DotRoot />;
}

function Header({ children, ...props }) {
  return <HeaderRoot {...props}>{children}</HeaderRoot>;
}

function Direction({ children, direction, carrierName, ...props }) {
  return (
    <DirectionRoot {...props}>
      <DirectionIcon icon={directionIcon[direction]} />
      <FormattedMessage
        id={`${direction.toLowerCase()}.label`}
        description={direction}
        defaultMessage={direction}
        tagName={'span'}
      />
      {carrierName && <CarrierName>({carrierName})</CarrierName>}
    </DirectionRoot>
  );
}

function IncludedServices({ children, includedService, ...props }) {
  return (
    <IncludedServicesRoot {...props}>
      <CheckmarkWrap>
        <Checkmark />
      </CheckmarkWrap>
      <IncludedServicesWrap>
        <Service>{includedService.quantity}</Service>&nbsp;x &nbsp;
        <Service>{includedService.maxWeight ? includedService.maxWeight.value : includedService.description}</Service>
        <Service>{includedService.maxWeight && includedService.maxWeight.unitOfMeasure}</Service>
        <Service>{children}</Service>
      </IncludedServicesWrap>
    </IncludedServicesRoot>
  );
}

export default function UpgradesCommonComponent({ children, ...props }) {
  return <Root {...props}>{children}</Root>;
}

UpgradesCommonComponent.Dot = Dot;
UpgradesCommonComponent.Header = Header;
UpgradesCommonComponent.IncludedServices = IncludedServices;
UpgradesCommonComponent.Direction = Direction;

const Root = styled.div`
  flex: 0 0 49%;

  ${mediaQueries.fromAndBelow.desktop`
		flex: 0 0 100%;
  `}
`;

const DotRoot = styled.span`
  display: inline-block;
  position: relative;
  top: 3px;
  left: 4px;
  background: ${({ theme }) => theme.colors.inputs.radio.backgroundChecked};
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 20px;
`;

const HeaderRoot = styled.div`
  color: ${({ theme }) => theme.colors.text.default};
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 16px;
  display: flex;
`;

const IncludedServicesRoot = styled.div`
  display: flex;
  margin-bottom: 8px;
  border: ${({ theme }) => `solid 1px ${rgba(theme.colors.inputs.radio.backgroundChecked, 0.5)}`};
  padding: 9px 0px 9px 10px;
  align-items: 12px;
`;

const IncludedServicesWrap = styled.div`
  padding-top: 2px;
`;

const CheckmarkWrap = styled.div`
  position: relative;
  height: 18px;
  width: 18px;
  opacity: 0.5;
  background-color: ${({ theme }) => theme.colors.inputs.radio.backgroundChecked};
  border: none;
  border-radius: 50%;
  margin-right: 12px;
`;

const Checkmark = styled.span`
  position: absolute;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
`;

const Service = styled.span`
  font-size: 1.4em;
  color: ${({ theme }) => theme.colors.text.default};
  font-weight: 100;
  margin-right: 5px;
  &:last-child {
    font-weight: 600;
  }
`;

const DirectionRoot = styled.div`
  font-size: 1.4em;

  font-weight: 600;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.text.default};

  span {
    margin-left: 12px;
  }
`;

const DirectionIcon = styled(FontAwesomeIcon)`
  font-size: 1em;
  color: ${({ theme }) => theme.colors.buttons.grey};
`;

const CarrierName = styled.span`
  margin-left: 8px;
`;
